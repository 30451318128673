import { Injectable } from '@angular/core';
import { BleClient, dataViewToText, textToDataView, numbersToDataView, numberToUUID, ScanResult, ConnectionPriority } from '@capacitor-community/bluetooth-le';
import { HttpClient } from '@angular/common/http';
import { Filesystem, Encoding } from '@capacitor/filesystem';
import { Global } from './env';
import { BluetoothClassic } from '@capacitor-inout/bluetoothclassic';
import { WiFi } from '@capacitor-inout/wifi';
import { Observable, Subject } from 'rxjs';

const BLE_NAME = "In/Out";
const UUID_GATT = "0000fff0-0000-1000-8000-00805f9b34fb";
const UUID_VERSION = "0000fff1-0000-1000-8000-00805f9b34fb";
const UUID_AUTH1 = "0000fff2-0000-1000-8000-00805f9b34fb";
const UUID_AUTH2 = "0000fff3-0000-1000-8000-00805f9b34fb";
const UUID_CFG = "0000fff4-0000-1000-8000-00805f9b34fb";
const UUID_STREAM = "0000fff5-0000-1000-8000-00805f9b34fb";
const UUID_CFG2 = "0000fff6-0000-1000-8000-00805f9b34fb";
const UUID_DATA = "0000fff7-0000-1000-8000-00805f9b34fb";
const CHUNK_BLE = 181;
const CHUNK_CLASSIC = 512;

enum State {
	CONNECTING/*0*/, CONNECTED/*1*/, SUSPENDED/*2*/, DISCONNECTING/*3*/, DISCONNECTED/*4*/, UNKNOWN/*5*/
};

declare var JMuxer: any;
declare var Player: any;

@Injectable({
  providedIn: 'root'
})

export class BleService {
connectedBLE: number[] = [0, 0];
connectedWiFi: number = 0;
streaming: boolean = false;
i_connectedWiFi: number = 0;
motor: number = 0;
deviceID: string[] = ["", ""];
deviceName: string[] = ["", ""];
firmwareDeviceVersion: string;
deviceAddr: string;
imgEl = null;
frame: Uint8Array;
expectedLength: number = -1;
receivedLength;
jmuxer = null;
useMuxer: boolean = false;
broadway = null;
lastTouch = null;
bleClientInitialized: boolean = false;
wifiState: State = State.UNKNOWN;
socket = null;
socketStart = 0;
wifiHotspotSuccessOnce: boolean = false;
umpiringO;
umpiringObs;
battery: number = -1;
linedeviceO;
linedeviceObs;
senddebugO;
senddebugObs;
calibrationStatus: number[] = [0, 0]; //0:nothing, 1:video, 2:process (play click), 3:showimage, 4:showoverlay, 5:done

constructor(private global: Global, private httpClient: HttpClient) {
	this.umpiringO = Observable.create((obs) => {
		this.umpiringObs = obs;
		return () => {}
	});
	this.linedeviceO = Observable.create((obs) => {
		this.linedeviceObs = obs;
		return () => {}
	});
	this.senddebugO = Observable.create((obs) => {
		this.senddebugObs = obs;
		return () => {}
	});
	BluetoothClassic.addListener("msgConnection", (v) => {
		if (v?.st == "connected") {
			console.log("msgConnection" + JSON.stringify(v));
			this.connectedBLE[0] = 1;
			this.global.refreshUI.next(true);
		} else if (v?.st == "disconnected") {
			console.log("msgConnection" + JSON.stringify(v));
			this.connectedBLE[0] = 0;
			this.streaming = false;
			this.global.refreshUI.next(true);
		} else if (v?.st == "data") {
			const buffer = Uint8Array.from(atob(v.value), c => c.charCodeAt(0));
			this.handleStream(new DataView(buffer.buffer));
		}
	});
	this.bleClientInitialize();
	WiFi.addListener("msgConnection", (r) => { this.msgConnectionWiFiDirect(this.i_connectedWiFi, r); });
	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.msg_connection((err: any, r: any) => {
			console.log("WiFiHotspot status:" + JSON.stringify(r));
			this.wifiState = r["ret"];
			this.wifiToastEnd();
			if (this.wifiState == State.CONNECTED) {
				console.log("WiFiHotspot connected");
				this.wifiHotspotSuccessOnce = true;
				this.wifiIsConnected(this.i_connectedWiFi);
			} else
				this.wifiToastFailure();
		});
	}
}

async bleClientInitialize() {
	try {
		await BleClient.initialize();
		this.bleClientInitialized = true;
		return;
	} catch(e) {}
}

async msgConnectionWiFiDirect(i, r) {
	console.log("msgConnectionWiFiDirect: " + JSON.stringify(r));
	if (r["ret"] == "pressPbc")
		setTimeout(() => { this.pressPbc(i); }, 1000);
	else {
		this.wifiState = r["ret"];
		this.wifiToastEnd();
		if (r["ret"] == State.CONNECTED) {
			console.log("WiFiDirect connected status:1");
			this.wifiIsConnected(i);
		} else
			this.wifiToastFailure();
		this.global.refreshUI.next(true);
	}
}

wifiToastConnecting() {
	let msg = this.global.mytranslateP("settings", "Connecting to the device Wi-Fi...");
	if (!this.wifiHotspotSuccessOnce && this.global.settings.videoTransport == "wifiHotspot" && (this.global.plt.is("android") || this.global.plt.is("ios")))
		msg += " " + this.global.mytranslateP("settings", "Please accept the connection when the popup window appears.");
	this.global.presentToast(msg, 0);
}

wifiToastFailure() {
	this.global.presentToast(this.global.mytranslateP("settings", "Connection over Wi-Fi has failed."), 2000);
}

wifiToastEnd() {
	this.global.dismissToast();
}

async wifiDirectConnect(i) {
	console.log("wifiDirectConnect");
	this.wifiToastConnecting();
	await BleClient.write(this.deviceID[i], UUID_GATT, UUID_CFG2, textToDataView(this.global.settings.videoTransport + "\0"));
	let loop:boolean = true;
	while (loop) {
		await BleClient.read(this.deviceID[i], UUID_GATT, UUID_CFG2).then(async (value) => {
			const ar = new Uint8Array(value.buffer);
			let ar_ = new TextDecoder().decode(ar);
			ar_ = ar_.replace(/\0.*/, '');
			if (ar_.startsWith("wifiDirect:")) {
				loop = false;
				const r = await WiFi.statusDirect();
				console.log("WiFiDirect status:" + JSON.stringify(r));
				this.wifiState = r["ret"];
				if (this.wifiState == State.CONNECTED) {
					console.log("WiFiDirect connected status:2");
					this.wifiToastEnd();
					this.wifiIsConnected(i);
				} else
					WiFi.connectDirect();
			}
		});
		this.global.sleepms(1000);
	}
}

wifiLaunchSettings() {
	WiFi.launchSettings();
}

async wifiHotspotConnect(i) {
	console.log("wifiHotspotConnect");
	this.wifiToastConnecting();
	await BleClient.write(this.deviceID[i], UUID_GATT, UUID_CFG2, textToDataView(this.global.settings.videoTransport + "\0"));
	let loop:boolean = true;
	while (loop) {
		await BleClient.read(this.deviceID[i], UUID_GATT, UUID_CFG2).then(value => {
			const ar = new Uint8Array(value.buffer);
			let ar_ = new TextDecoder().decode(ar);
			ar_ = ar_.replace(/\0.*/, '');
			if (ar_.startsWith("wifiHotspot:")) {
				loop = false;
				const ssid = ar_.slice(("wifiHotspot:").length);
				console.log("WiFi.connectHotspot to #" + ssid + "#");
				if (typeof (<any>window).electron != "undefined")
					(<any>window).electron.ipc.invoke("wifiConnectHotspot", ssid).then(async (d) => {
					});
				else
					WiFi.connectHotspot({ssid:ssid, passphrase:"theinout"}).then((r) => {
						console.log("WiFiHotspot status:" + JSON.stringify(r));
						this.wifiState = r["ret"];
						this.wifiToastEnd();
						if (this.wifiState == State.CONNECTED) {
							console.log("WiFiHotspot connected");
							this.wifiHotspotSuccessOnce = true;
							this.wifiIsConnected(i);
						} else
							this.wifiToastFailure();
					});
			}
		});
		this.global.sleepms(1000);
	}
}

wifiHotspotDisconnect() {
	console.log("wifiHotspotConnect");
	WiFi.disconnectHotspot();
	this.connectedWiFi = 0;
}

wifiDirectDisconnect() {
	console.log("wifiDirectDisconnect");
	WiFi.disconnectDirect();
	this.connectedWiFi = 0;
}

myTouchEventReset() {
	this.lastTouch = null;
}

myTouchEvent() {
	const now = new Date().getTime();
	if ((this.connectedBLE[0] == 2 || this.connectedBLE[1] == 2) && this.motor != 0 && this.lastTouch != null && (now - this.lastTouch) > 1000) {
		this.lastTouch = now;
		console.log("WARNING: Touch detected while moving. Stopping now.");
		this.motorAction(2, 0);
	}
	if ((this.connectedBLE[0] == 2 || this.connectedBLE[1] == 2) && this.motor != 0)
		this.lastTouch = now;
	else
		this.lastTouch = null;
}

action(i, a) {
	if (this.global.settings.useBluetoothClassic)
		BluetoothClassic.action({value: a});
	else {
		if (i == 2) {
			BleClient.write(this.deviceID[0], UUID_GATT, UUID_CFG, numbersToDataView([a]));
			BleClient.write(this.deviceID[1], UUID_GATT, UUID_CFG, numbersToDataView([a]));
		} else if (i == -1)
			BleClient.write(this.deviceID[this.i_connectedWiFi], UUID_GATT, UUID_CFG, numbersToDataView([a]));
		else
			BleClient.write(this.deviceID[i], UUID_GATT, UUID_CFG, numbersToDataView([a]));
	}
}

connectToggle(i) {
	if (i == -1) {
		if (this.connectedBLE[0] == 2 && this.connectedBLE[1] == 2 && this.connectedWiFi == 3)
			 i = this.i_connectedWiFi;
		else
			i = this.connectedBLE[1] == 2 ? 1 : 0;
	}
	if (this.connectedBLE[i] == 2)
		this.disconnect(i);
	else if (this.connectedBLE[i] == 1) {
		this.streaming = false;
		this.connectedBLE[i] = 0;
		this.global.refreshUI.next(true);
		this.stopScan();
	} else
		this.tryConnect(i);
}


async stopScan() {
	console.log("Stop Scan");
	if (!this.global.settings.useBluetoothClassic)
		await BleClient.stopLEScan();
}

async disconnect(i) {
	if (this.connectedWiFi == 3) {
		this.socketStart = 0;
		this.socket.close();
	}
	this.wifiToastEnd();
	if (this.global.settings.videoTransport == "wifiDirect")
		await this.wifiDirectDisconnect();
	else if (this.global.settings.videoTransport == "wifiHotspot")
		await this.wifiHotspotDisconnect();
	this.wifiState = State.DISCONNECTED;
	await BleClient.disconnect(this.deviceID[i]);
	if (this.motor != 0)
		await this.motorAction(i, 0);
	this.streaming = false;
	this.connectedWiFi = 0;
	this.connectedBLE[i] = 0;
	this.global.refreshUI.next(true);
	await this.stopScan();
}

async reconnectToOtherWiFi() {
	if (this.connectedWiFi == 3) {
		this.socketStart = 0;
		this.socket.close();
	}
	this.wifiToastEnd();
	if (this.global.settings.videoTransport == "wifiDirect")
		await this.wifiDirectDisconnect();
	else if (this.global.settings.videoTransport == "wifiHotspot")
		await this.wifiHotspotDisconnect();
	this.wifiState = State.DISCONNECTED;
	this.streaming = false;
	this.connectedWiFi = 1;
	this.i_connectedWiFi = 1 - this.i_connectedWiFi;
	this.global.refreshUI.next(true);
	if (this.global.settings.videoTransport == "wifiDirect")
		this.wifiDirectConnect(this.i_connectedWiFi);
	else if (this.global.settings.videoTransport == "wifiHotspot")
		this.wifiHotspotConnect(this.i_connectedWiFi);
}

async tryConnect(i) {
	console.log("tryConnect");
	this.streaming = false;
	this.connectedBLE[i] = 1;
	this.global.refreshUI.next(true);
	if (this.global.settings.useBluetoothClassic) {
		BluetoothClassic.connect({mac: this.deviceAddr});
	} else {
		if (!this.bleClientInitialized)
			await this.bleClientInitialize();
		try {
			if (!await BleClient.isEnabled())
				await BleClient.requestEnable();
		} catch(e) {
			console.log("BLE enable() not working " + e.message);
		}
		console.log("BLE isEnabled:" + await BleClient.isEnabled());
		if (!this.global.plt.is("android") && !this.global.plt.is("ios")) {
			try {
				const bled = await BleClient.requestDevice({optionalServices:[UUID_GATT]});
				await this.connectToBluetoothDevice(i, bled.deviceId);
			} catch(e) {
				this.streaming = false;
				this.connectedBLE[i] = 0;
				this.global.refreshUI.next(true);
			}
		} else {
			await BleClient.requestLEScan({services:[]}, this.onBluetoothDeviceFound.bind(this, i));
		}
	}
}

async onBluetoothDeviceFound(i, result) {
	//console.log("onBluetoothDeviceFound " + JSON.stringify(result));
	if (result.device.name != undefined && result.device.name.startsWith(BLE_NAME)) {
		if (this.global.settings.twoDevices &&(this.deviceID[i] != "" || result.device.deviceId == this.deviceID[1 - i]))
			return;
		this.deviceID[i] = result.device.deviceId;
		this.deviceName[i] = result.device.name;
		console.log("onBluetoothDeviceFound " + result.device.deviceId);
		console.log("Found device#" + i + ". Connecting to " + this.deviceID[i] + " (" + this.deviceName + ")...");
		await this.stopScan();
		await this.connectToBluetoothDevice(i, result.device.deviceId);
	}
}

bleNotifyData0Cb = ((value) => {
	var bb = new Uint8Array(value.buffer);
	this.handleMsg(0, value.buffer);
});

bleNotifyData1Cb = ((value) => {
	this.handleMsg(1, value.buffer);
});

async connectToBluetoothDevice(i, devId: string) {
	let err = 0;
	const a = await BleClient.connect(devId, (dId) => {this.onDeviceDisconnected(i, dId);}).catch(error => {
		console.log("connect error: ", error);
		err = 1;
	});
	if (err == 1) {
		this.streaming = false;
		this.connectedBLE[i] = 0;
		this.global.refreshUI.next(true);
		return;
	}
	console.log("connectToBluetoothDevice device#" + i + " success!");
	this.streaming = false;
	this.connectedBLE[i] = 2;
	this.global.refreshUI.next(true);
	this.listServices(devId);
	await this.version(i);
	await this.syncDate(i);
	this.sendToDevice(i, "version " + this.global.VERSION);
	if (this.connectedBLE[0] == 2 && this.connectedBLE[1] == 2) {
		BleClient.write(this.deviceID[0], UUID_GATT, UUID_CFG2, textToDataView("pair 0_" + this.deviceID[1] + "\0"));
		BleClient.write(this.deviceID[1], UUID_GATT, UUID_CFG2, textToDataView("pair 0_" + this.deviceID[0] + "\0"));
	}
	if (this.connectedWiFi == 0) {
		this.connectedWiFi = 1;
		this.i_connectedWiFi = i;
		if (this.global.settings.videoTransport == "wifiDirect")
			this.wifiDirectConnect(i);
		else if (this.global.settings.videoTransport == "wifiHotspot")
			this.wifiHotspotConnect(i);
	}
	if (this.global.plt.is("android")) {
		console.log("Request HIGH ConnectionPriority for interval");
		BleClient.requestConnectionPriority(devId, ConnectionPriority.CONNECTION_PRIORITY_HIGH);
	}
	BleClient.startNotifications(devId, UUID_GATT, UUID_DATA, i == 1 ? this.bleNotifyData1Cb : this.bleNotifyData0Cb).catch(error => {
		console.log("startNotifications UUID_DATA error: ", error)
	});
}

onDeviceDisconnected(i, disconnectedDeviceId: string) {
	this.deviceID[i] = "";
	this.deviceName[i] = "";
	this.connectedBLE[i] = 0;
	if (!this.global.settings.twoDevices) {
		this.streaming = false;
		this.connectedWiFi = 0;
	}
	this.global.refreshUI.next(true);
	console.log("Disconnected device#" + i + " " + disconnectedDeviceId);
}

async listServices(deviceId: string) {
	try {
		await BleClient.getServices(deviceId).then((value) => {
			//console.log("services " + JSON.stringify(value));
			let found:boolean = false;
			for (let i = 0; i < value.length; i++) {
				let cvalue = value[i]["characteristics"];
				for (let j = 0; j < cvalue.length; j++) {
					if (cvalue[j]["uuid"] == "0000fff7-0000-1000-8000-00805f9b34fb") {
						found = true;
						break;
					}
				}
			}
			if (!found) {
				console.log("Error Bluetooth Cache");
				//this.global.presentAlert("Reboot this phone", "Error Bluetooth Cache", "You need to reboot your phone before continuing. If the problem persists, contact the support team");
			}
		});
	} catch (e) {
	  console.log("getServices Error " + e.message());
	}
}

async signalLightBuzzer(i, buzzer, red, green, blue) {
    let action = (buzzer & 0x0) | ((red != 0 ? 1 : green != 0 ? 2 : blue != 0 ? 3 : 0) << 2);
    action = (1 + (action << 4));
	console.log("Sending to Device#" + i + " signal action:" + action + "=0x" + action.toString(16));
	this.action(i, action);
}

async motorAction(i, a) {
	if (a == this.motor)
		a = 0;
	if (a == 2 && this.global.settings.calibrateRotationDone == false) {
		let ret = await this.global.presentQuestion("Calibration required", "Have you calibrated the rotation of the Net Device yet?", "Calibration should be done only once and takes just a few seconds.");
		if (ret)
			this.global.settings.calibrateRotationDone = true;
		else {
			this.global.openPage("helpCalibration", false);
			//this.global.openBrowser("https://support.inout.tennis");
			return;
		}
	}
    let action = a == 4 ? 20 : a == 3 ? 36 : a == 2 ? 15 : a == 1 ? 16 : a == -1 ? 14 : 0x0;
	this.motor = a;
	this.global.refreshUI.next(true);
	console.log("Sending signal action:" + action + "=0x" + action.toString(16));
	if (action == 14) {
		this.action(i, 86);
		this.action(i, 167);
	}
	this.action(i, action);
	this.myTouchEventReset();
}

async calibrateRotation(i) {
	this.action(i, 0);
    let action = 0xd;
	console.log("Sending signal action:" + action + "=0x" + action.toString(16));
	this.action(i, action);
}

async shutdown(i) {
    let action = 0xc;
	console.log("Sending signal action:" + action + "=0x" + action.toString(16));
	this.action(i, action);
}

async checkVersion(i) {
		if (this.firmwareDeviceVersion < this.global.firmwareServerVersion && this.global.currentUrl != "upgrade") {
			let ret = await this.global.presentQuestion("Upgrade needed", "Do you want to upgrade the device now?", "The firmware of the device is too old and not compatible with this app.");
			if (ret)
				this.global.openPage("upgrade", false);
		}
}

async syncDate(i) {
	const d = new Date();
	const localTime = Math.floor(d.getTime() / 1000 - (d.getTimezoneOffset() * 60));
	await BleClient.write(this.deviceID[i], UUID_GATT, UUID_CFG2, textToDataView("date " + localTime + "\0"));
}

async version(i) {
	await BleClient.read(this.deviceID[i], UUID_GATT, UUID_VERSION).then(value => {
		const ar = new Uint8Array(value.buffer);
		this.firmwareDeviceVersion = new TextDecoder().decode(ar);
		console.log("Device#" + i + " version " + this.firmwareDeviceVersion);
		this.global.refreshUI.next(true);
		this.checkVersion(i);
	});
}

encode(input) {
	var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	var output = "";
	var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
	var i = 0;
	while (i < input.length) {
		chr1 = input[i++];
		chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
		chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here
		enc1 = chr1 >> 2;
		enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
		enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
		enc4 = chr3 & 63;
		if (isNaN(chr2))
			enc3 = enc4 = 64;
		else if (isNaN(chr3))
			enc4 = 64;
		output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
	}
	return output;
}

totalFps;
totalBytes;
nowLast = 0;
handleStream(value) {
	const chunk = this.global.settings.useBluetoothClassic ? CHUNK_CLASSIC : CHUNK_BLE;
	const now = Date.now();
	this.totalBytes += value.buffer.byteLength;
	if (now - this.nowLast > 1000) {
		//console.log("" + (this.totalBytes / 1024).toFixed(1) + "kiB/s " + this.totalFps + "fps\n");
		this.totalFps = 0;
		this.totalBytes = 0;
		this.nowLast = now;
	}
	if (this.expectedLength == -1 && value.buffer.byteLength == 2) {
		this.expectedLength = value.getUint16(0, true);
		this.frame = new Uint8Array(this.expectedLength);
		this.receivedLength = 0;
	} else if (this.expectedLength != -1) {
		var tmp = new Uint8Array(value.buffer);
		this.frame.set(tmp.slice(1), value.getUint8(0, true) * chunk);
		this.receivedLength += value.buffer.byteLength - 1;
		if (this.receivedLength >= this.expectedLength) {
			//console.log((this.receivedLength == this.expectedLength ? "GOOD:" : "BAD:") + this.receivedLength + "/" + this.expectedLength);
			this.totalFps++;
			if (this.global.videoEncoding == "mjpeg") {
				var blob = new Blob([this.frame], {type: "image/jpeg"});
				var url = URL.createObjectURL(blob);
				if (this.imgEl == null)
					this.imgEl = document.getElementById("imgDeviceID_" + this.global.currentUrl.substr(8));
				this.imgEl.src = url;
			} else if (!this.useMuxer || this.global.plt.is("ios"))
				this.broadway.decode(new Uint8Array(this.frame));
			else
				this.jmuxer.feed({video: this.frame});
			this.expectedLength = -1;
		}
	}
}

bleNotifyStreamCb = (value => {
	this.handleStream(value);
});

async wifiIsConnected(i) {
	console.log("wifiIsConnected Device#" + i);
	this.connectedWiFi = 2;
	await this.global.sleepms(1000);
	this.socketStart = new Date().getTime();
	this.socketInit("10.10.10.1");
	if (!this.streaming && this.global.currentUrl.substr(8) == "Camera")
		this.toggleStream();
}

async identifyDevice(i, wait:boolean) {
	this.sendToDevice(i, "signalyellow");
	if (wait)
		await this.global.sleepms(1000);
}

sendToDevice(i, s) {
	if (this.connectedBLE[0] == 2 && (i == 2 || i == 0))
		BleClient.write(this.deviceID[0], UUID_GATT, UUID_CFG2, textToDataView(s + "\0"));
	if (this.connectedBLE[1] == 2 && (i == 2 || i == 1))
		BleClient.write(this.deviceID[1], UUID_GATT, UUID_CFG2, textToDataView(s + "\0"));
//	if (this.socket)
//		this.socket.send("" + (!s.startsWith("signal") ? "v4v4" : "") + s);
}

socketReceiveString = "";
handleMsg(i, data) {
	var buffer = new Uint8Array(data);
	function decoder(input, start) {
		var st = "";
		for (let ii = start; ii < input.length; ii++)
			st += String.fromCharCode(input[ii]);
		return st;
	}
	if (buffer[0] == 1) {
		this.socketReceiveString = decoder(buffer, 1);
		console.log("Socket: Message Device#" + i + " #" + this.socketReceiveString + "#");
		if (this.socketReceiveString.startsWith("umpiring:") && typeof this.umpiringObs != "undefined")
			this.umpiringObs.next({msg:this.socketReceiveString, data:null, device:i});
		else if (this.socketReceiveString.startsWith("linedevice:") && typeof this.linedeviceObs != "undefined")
			this.linedeviceObs.next({msg:this.socketReceiveString, data:null, device:i});
		else if (this.socketReceiveString.startsWith("senddebug:") && typeof this.senddebugObs != "undefined")
			this.senddebugObs.next({msg:this.socketReceiveString, data:null, device:i});
		else if (this.socketReceiveString.startsWith("battery:")) {
			const volt = parseInt(this.socketReceiveString.slice(8));
			console.log("Battery voltage:" + volt);
			this.battery = volt > 3800 ? 4 : volt > 3600 ? 3 : volt > 3400 ? 2 : volt > 3200 ? 1 : 0;
			this.global.refreshUI.next(true);
		} else if (this.socketReceiveString.startsWith("stats:")) {
			this.manageStats(this.socketReceiveString.slice(6));
		}
	} else if (buffer[0] == 2) {
		console.log("Socket: File size:" + (buffer.length - 1));
		if (this.socketReceiveString.indexOf("file:camab.zip") != -1 || this.socketReceiveString.indexOf("file:cama.zip") != -1 || this.socketReceiveString.indexOf("file:camb.zip") != -1)
			this.calibDebug(buffer.slice(1));
		else if (this.socketReceiveString.indexOf("file:stats_") != -1)
			this.storeStats(this.socketReceiveString.slice(11).replace(/\:.*/g, ""), buffer.slice(1));
		else if (this.socketReceiveString.indexOf("file:debug_") != -1)
			this.storeDebug(this.socketReceiveString.slice(5).replace(/\:.*/g, ""), buffer.slice(1));
		else
			this.umpiringObs.next({msg:this.socketReceiveString, data:buffer.slice(1), device:i});
	} else if (buffer[0] == 3) {
		this.umpiringObs.next({msg:"binary", data:buffer.slice(1), device:i});
	} else if (buffer[0] == 47) {
		if (this.global.videoEncoding == "mjpeg") {
			var blob = new Blob([data.slice(1)], {type: "image/jpeg"});
			var url = URL.createObjectURL(blob);
			if (this.imgEl == null)
				this.imgEl = document.getElementById("imgDeviceID_" + this.global.currentUrl.substr(8));
			this.imgEl.src = url;
		} else if (!this.useMuxer || this.global.plt.is("ios"))
			this.broadway.decode(new Uint8Array(data.slice(1)));
		else
			this.jmuxer.feed({video: new Uint8Array(data.slice(1))});
	}
}

async socketInit(host) {
	const url = "ws" + (this.global.plt.is("android") ? "s" : "") + "://" + host + ":" + (this.global.plt.is("android") ? 8081 : 8080);
	console.log("socketInit " + url + " for device " + this.firmwareDeviceVersion);
	this.socket = new WebSocket(url);
	this.socket.binaryType = "arraybuffer";
	this.socket.onopen = () => {
		console.log("socket onopen");
		this.connectedWiFi = 3;
		this.identifyDevice(this.i_connectedWiFi, false);
		this.global.refreshUI.next(true);
	}
	this.socket.onerror = (e) => {
		this.connectedWiFi = 2;
		console.log("socket onerror " + JSON.stringify(e));
	}
	this.socket.onclose = (event) => {
		this.connectedWiFi = 2;
		this.socket = null;
		console.log("socket onclose " + event.code);
		if (this.socketStart != 0 && new Date().getTime() - this.socketStart < 30000)
			this.socketInit(host);
	}

	this.socketReceiveString = "";
	this.socket.onmessage = (msg) => {
		this.handleMsg(this.i_connectedWiFi, msg.data);
	}
}

async storeStats(st, data) {
	console.log("Storing stats #" + st + "#");
	Filesystem.writeFile({
		path: this.global.STATSDOCUMENTSFOLDER + "/" + st,
		data: this.global.arraybuffer_to_base64(data),
		directory: this.global.STATSBASEDIRECTORY,
	});
}

async storeDebug(st, data) {
	console.log("Storing debug #" + st + "#");
	await this.global.checkFolder(this.global.DEBUGBASEDIRECTORY, this.global.DEBUGDOCUMENTSFOLDER);
	if (typeof (<any>window).electron != "undefined") {//Electron
		(<any>window).electron.ipc.invoke("fileWrite", "./InOut/Debug/" + st, data);
	} else if (!this.global.plt.is("electron") && this.global.plt.is("desktop")) {//Web
	} else {//Android, iOS
		Filesystem.writeFile({
			path: this.global.DEBUGDOCUMENTSFOLDER + "/" + st,
			data: this.global.arraybuffer_to_base64(data),
			directory: this.global.DEBUGBASEDIRECTORY,
		});
	}
	this.senddebugObs.next({msg:"senddebug:end", data:null, device:0});
}

async manageStats(st) {
	const missing = Array();
	const st_ = st.split(":");
	for (let i = 0; i < st_.length; i++) {
		try {
			const ret = await Filesystem.stat({
				path: this.global.STATSDOCUMENTSFOLDER + "/" + st_[i] + ".inout",
				directory: this.global.STATSBASEDIRECTORY,
			});
		} catch(e) {
			missing.push(st_[i]);
		}
	}
	for (let i = 0; i < missing.length; i++)
		this.sendToDevice(0, "request_stats_" + missing[i]);
}

pressPbc(i) {
	const action = 2;
	console.log("WifiDirect Sending pressPbc action:" + action + "=0x" + action.toString(16));
	this.action(i, action);
}

startStream() {
	if (this.connectedBLE[this.i_connectedWiFi] == 2 && (this.global.settings.videoTransport == "bluetooth" || this.wifiState == State.CONNECTED) && !this.streaming) {
		if (this.global.videoEncoding == "h264") {
			if (!this.useMuxer || this.global.plt.is("ios")) {
				var myNode = document.getElementById("divDeviceID_" + this.global.currentUrl.substr(8));
				while (myNode.firstChild)
					myNode.removeChild(myNode.lastChild);
				this.broadway = new Player({
					useWorker: true,
					workerFile: "/assets/broadway/Decoder.js"
				});
				myNode.appendChild(this.broadway.canvas);
				this.broadway.canvas.style.width = myNode.style.width;
				//this.broadway.canvas.style.height = "180px";
				this.broadway.onPictureDecoded = (_, w, h) => {
					//console.log("" + w + "x" + h);
				};
			} else {
				this.jmuxer = new JMuxer({
					node: "videoDeviceID_" + this.global.currentUrl.substr(8),
					mode: "video",
					flushingTime: 50,
					fps: 20,
					debug: false
				});
			}
		}
		let action = 5 + (1 << 4) + (this.global.videoEncoding == "mjpeg" ? (2 << 4) : 0);
		this.action(this.i_connectedWiFi, action);
		console.log("Sending stream action:" + action + "=0x" + action.toString(16));
		if (!this.global.settings.useBluetoothClassic && (this.global.settings.videoTransport == "bluetooth" || this.wifiState != State.CONNECTED))
			BleClient.startNotifications(this.deviceID[this.i_connectedWiFi], UUID_GATT, UUID_STREAM, this.bleNotifyStreamCb).catch(error => {
				console.log("startNotifications UUID_STREAM error: ", error)
			});
		this.streaming = true;
	} else
		console.log("startStream doing nothing");
}

async stopStream() {
	if (this.connectedBLE[this.i_connectedWiFi] && this.streaming) {
		let action = 5 + (0 << 4);
		console.log("Sending stream action:" + action + "=0x" + action.toString(16));
		this.streaming = false;
		if (!this.global.settings.useBluetoothClassic) {
			await BleClient.stopNotifications(this.deviceID[this.i_connectedWiFi], UUID_GATT, UUID_STREAM).catch(error => {
				console.log("stopNotifications error: ", error)
			});
			await BleClient.stopNotifications(this.deviceID[this.i_connectedWiFi], UUID_GATT, UUID_CFG2).catch(error => {
				console.log("stopNotifications error: ", error)
			});
		}
		this.action(this.i_connectedWiFi, action);
	} else
		console.log("stopStream doing nothing");
}

async toggleStream() {
	console.log("toggleStream");
	if (this.streaming)
		await this.stopStream();
	else
		this.startStream();
}

async getCalibrationDebug() {
	this.global.popoverController.dismiss();
	const ret = await this.global.presentQuestion("Debug Calibration", "Do you want to send the calibration image to the support team?", "The full image will be retrieved from the device and send to the In/Out server in the background.");
	if (ret)
		this.sendToDevice(0, "request_cama");
}

async calibDebug(data) {
	if (this.connectedWiFi == 3 && this.global.settings.videoTransport == "wifiHotspot") {
		setTimeout((d) => { this.calibDebug(d); }, 10000, data);
		return;
	}
	const response = await this.httpClient.post(this.global.PLAYERURL + "/cloud/master/calibDebug.json", {playerID:this.global.settings.user.playerID, deviceID:this.global.settings.deviceID, c:data}, {headers:{"content-type": "application/x-www-form-urlencoded"}}).toPromise();
	console.log("calibDebug.json returns " + JSON.stringify(response));
}

}
