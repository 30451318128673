import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Global } from './env';

const routes: Routes = [
	{
		path: 'tabs',
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsModule)
	},
	{
		path: 'about',
		loadChildren: () => import('./about/about.module').then( m => m.AboutModule)
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then( m => m.SettingsModule)
	},
	{
		path: 'help',
		loadChildren: () => import('./help/help.module').then( m => m.HelpModule)
	},
	{
		path: 'upgrade',
		loadChildren: () => import('./upgrade/upgrade.module').then( m => m.UpgradeModule)
	},
	{
		path: 'upgradeLineDevice',
		loadChildren: () => import('./upgradeLineDevice/upgradeLineDevice.module').then( m => m.UpgradeLineDeviceModule)
	},
	{
		path: 'sendDebug',
		loadChildren: () => import('./sendDebug/sendDebug.module').then( m => m.SendDebugModule)
	},
	{
		path: 'helpCalibration',
		loadChildren: () => import('./helpCalibration/helpCalibration.module').then( m => m.HelpCalibrationModule)
	},
	{
		path: 'selection',
		loadChildren: () => import('./selection/selection.module').then( m => m.SelectionModule)
	},
	{
		path: 'splash',
		loadChildren: () => import('./splash/splash.module').then( m => m.SplashModule)
	},
	{
		path: '**',
		redirectTo: 'splash',
		pathMatch: 'full'
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})

export class AppRoutingModule {

constructor(private global: Global) {
	console.log("Version: " + global.VERSION);
	console.log("Platform: " + global.plt.platforms());
	global.currentUrl = "selection";
}

}
