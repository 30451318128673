import { Component, ChangeDetectorRef } from '@angular/core';
import { BleService } from './ble';
import { Location } from '@angular/common';
import { Router, NavigationStart, NavigationEnd, Event } from "@angular/router";
import { Global } from './env';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})

export class AppComponent {
navigationByBack: boolean = false;

constructor(private global: Global, private ble: BleService, private location: Location, private router: Router, private cdr: ChangeDetectorRef) {
	global.plt.backButton.subscribeWithPriority(10, () => {
		if (window.location.href.endsWith("selection"))
			this.global.backButtonAlert();
		else
			this.location.back();
	});

	router.events.subscribe((event: Event) => {
		if (event instanceof NavigationEnd) {
			this.global.currentUrl = this.router.url.substr(1);
			this.global.myOrientation();
		}
	});

	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.language((err: any, v: string) => {
			this.changeLanguageAndRefresh(v);
		});
	}
}

async changeLanguageAndRefresh(v: string) {
	await this.global.changeLanguage(v);
	this.cdr.detectChanges();
	setTimeout(() => {this.cdr.detectChanges();}, 500);
}

}
