import { registerPlugin } from '@capacitor/core';

import type { WiFiPlugin } from './definitions';

const WiFi = registerPlugin<WiFiPlugin>('WiFi', {
  web: () => import('./web').then(m => new m.WiFiWeb()),
});

export * from './definitions';
export { WiFi };
